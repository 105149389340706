import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from 'lodash';
import {
	IMayPerformActionResponseList,
	IOrgUnitDto,
	KNOX_ACTIONS,
	KnoxSecurityService,
	SECURITY_ROUTES,
	SecurityCompanyService,
} from '@aex/security/shared';
import {BreadcrumbService} from "@aex/shared/root-services";

@Component({
	selector: 'app-security-dashboard',
	styleUrls: ['./security-dashboard.component.scss'],
	templateUrl: './security-dashboard.component.html',
})
export class SecurityDashboardComponent {
	public companies: IOrgUnitDto[];
	public orginalCompanies: IOrgUnitDto[];
	public selectedCompany: IOrgUnitDto;
	public get isCompanySelected(): boolean {
		return !isNil(this.selectedCompany);
	}
  KNOX_ACTIONS = KNOX_ACTIONS;

	// Route Urls
	public systemRolesRoute: string = SECURITY_ROUTES.systemRoles.name;
	public companyTypesRoute: string = SECURITY_ROUTES.companyTypes.name;
	public companyRolesRoute: string = SECURITY_ROUTES.companyRoles.name;
	public companyCustomerRolesRoute: string = SECURITY_ROUTES.companyCustomerRoles.name;
	public userRoute: string = SECURITY_ROUTES.users.name;
	public companyAssociationRoute: string = SECURITY_ROUTES.companyAssociation.name;
  public companyInheritedAccessRoute: string = SECURITY_ROUTES.companyInheritedAccess.name;

	// Set to false in LIVE
  // public knoxReadSecurity: string = KNOX_ACTIONS.ReadSecurity;

	// Security Properties
	public mayEnableSystemRoles: boolean = false;
	public mayEnableCompanyTypeRoles: boolean = false;
	public mayEnableRoles: boolean = false;
	public mayEnableCustomerRoles: boolean = false;
	public mayEnableUsers: boolean = false;
	public mayEnableCompanyAssociations: boolean = false;
  public mayEnableCompanyInheritedAccess: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly companyService: SecurityCompanyService,
		private readonly knoxSecurityService: KnoxSecurityService,
		private readonly breadcrumbService: BreadcrumbService

	) {
		this.route.data.subscribe(({ companies }) => {
			this.companies = companies;
			this.orginalCompanies = companies;
			const selectedCompanyId = this.companyService.getCompanyId();
			if (selectedCompanyId) {
				this.selectedCompany = this.companies.find(
						(vSearchItem: IOrgUnitDto) => vSearchItem.id === selectedCompanyId,
				);
				this.doSecurityPermissions(this.selectedCompany);
			}
		});
		this.breadcrumbService.resetRouteBreadcrumbItems([]);
	}

	public navigateToUrl(url: string): void {
		this.router.navigateByUrl(
			`security/${url}`,
			).then();
	}

	public onSelectedCompanyChange(selectedOrgUnitDto: IOrgUnitDto): void {
		let selectedCompany = selectedOrgUnitDto;

    this.doSecurityPermissions(selectedCompany);

    this.companyService.setCompanyDto(selectedCompany);
		this.companyService.setCompanyId(selectedCompany?.id);
		this.companyService.setCompanyName(selectedCompany?.org_unit_name);
	}

  private doInitialiseSecurityPermissions(): void {
    this.mayEnableSystemRoles = false;
    this.mayEnableCompanyTypeRoles = false;
    this.mayEnableRoles = false;
    this.mayEnableCustomerRoles = false;
    this.mayEnableUsers = false;
    this.mayEnableCompanyAssociations = false;
    this.mayEnableCompanyInheritedAccess = false;
  }

  private doSecurityPermissions(company: IOrgUnitDto): void {
    // Reset all permissions if no company is selected
    if (!company) {
      this.doInitialiseSecurityPermissions();
      return;
    }

    this.knoxSecurityService.userMayPerformActions(
      [
        KNOX_ACTIONS.AdminSecurity,
        KNOX_ACTIONS.ReadSecurity,
        KNOX_ACTIONS.ManageSecurity,
      ],
      [company.id],
    ).subscribe(
      (knoxActionResponses: IMayPerformActionResponseList) => {
        // Get Knox action Responses
        const mayAdminSecurity = this.knoxSecurityService.isAuthorised(knoxActionResponses.may_perform_action_responses[0]);
        const mayReadSecurity = this.knoxSecurityService.isAuthorised(knoxActionResponses.may_perform_action_responses[1]);
        const mayManageSecurity = this.knoxSecurityService.isAuthorised(knoxActionResponses.may_perform_action_responses[2]);

        const enableSecurity = mayReadSecurity || mayManageSecurity;

        // Set Security Permissions
        this.mayEnableSystemRoles = mayAdminSecurity;
        this.mayEnableCompanyTypeRoles = mayAdminSecurity;
        this.mayEnableRoles = enableSecurity;
        this.mayEnableCustomerRoles = mayAdminSecurity;
        this.mayEnableUsers = enableSecurity;
        this.mayEnableCompanyAssociations = enableSecurity;
        this.mayEnableCompanyInheritedAccess = mayAdminSecurity;
      },
    );
  }

	public handleFilterSelectedCompany(value: string) {
		this.companies = this.orginalCompanies.filter(
			(orgUnitDto) => orgUnitDto.org_unit_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
	}
}
